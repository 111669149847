const UNIQUE_KEY_IDENTIFIER_REGEX = /^unique_key_\d{3}$/;

export const getFormFieldKey = (key: string) => {
  if (key.split(';').length > 1) {
    const keyParts = key.split(';');
    if (keyParts[keyParts.length - 1].match(UNIQUE_KEY_IDENTIFIER_REGEX)) {
      keyParts.pop();
      return keyParts.join(';');
    }
  }
  return key;
};

import { FC, useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';

import logger from '@common/log';
import { stripAccolades } from '@common/string';
import { Recaptcha, RecaptchaRef } from '@components/Recaptcha/Recaptcha';
import { RecaptchaForm } from '@components/RecaptchaForm/RecaptchaForm';
import RichText from '@components/RichText/RichText';
import { useFormsPublicV2PostB2BForm } from '@dc/hooks';
import useDC from '@dc/useDC';
import { Placeholder } from '@sitecore/common';
import { RevolutionRegisterToNewsletterFormRendering } from '@sitecore/types/RevolutionRegisterToNewsletterForm';
import { Box, Button, InputText, Stack, Stretch, Text, TextLink, TransitionOpacity } from '@sparky';
import { useTracking } from '@tracking';

import { prepareRevolutionFormRequest } from '../GenericForm/util/request';

const newsletterFormSchema = yup.object({
  emailaddress1: yup.string().email().required(),
  recaptchaToken: yup.string().required(),
});
interface FormValues {
  emailaddress1: string;
  recaptchaToken: string;
  formId?: string;
  [key: string]: string | undefined;
}

export const RevolutionRegisterToNewsletterForm: FC<RevolutionRegisterToNewsletterFormRendering> = ({ fields }) => {
  const { label, businessUnit } = useDC();

  const { trackFormInteractionStart, trackFormInteractionCompleted } = useTracking();

  const {
    emailAddressFormField: { value: emailAddressValue },
    disclaimerContent,
    backButtonText,
    nextButtonText,
  } = fields;

  const recaptchaRef = useRef<RecaptchaRef>(null);
  const [isTransitioning, setTransitioning] = useState(false);
  const { send, reset, isError, isSuccess } = useFormsPublicV2PostB2BForm();

  const methods = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(newsletterFormSchema),
  });
  const {
    formState: { errors },
    register,
  } = methods;

  const submitForm: SubmitHandler<FormValues> = async values => {
    setTransitioning(true);

    try {
      if (values?.formId) {
        const requestBody = prepareRevolutionFormRequest(values.formId, values);

        await send({
          businessUnit,
          formId: stripAccolades(values.formId),
          label,
          requestBody,
          recaptchaToken: values.recaptchaToken,
        });
      } else {
        throw new Error('Form ID is required as a hidden field');
      }
    } catch (error) {
      logger.error('KSK1W5', 'Failed to submit form', { error });
    } finally {
      setTimeout(() => {
        setTransitioning(false);
      }, 500);
    }
  };

  const getEmailErrorMessage = () => {
    const defaultErrorMessage = errors.emailaddress1?.message;
    const requiredMessage = emailAddressValue?.requiredMessage;
    const validationMessage = emailAddressValue?.validationMessage;

    if (errors.emailaddress1?.type === 'required') {
      return requiredMessage ?? defaultErrorMessage;
    } else if (errors.emailaddress1?.type === 'email' && validationMessage) {
      return validationMessage ?? defaultErrorMessage;
    }

    return undefined;
  };

  useEffect(() => {
    if (isSuccess) {
      trackFormInteractionCompleted();
    }
  }, [isSuccess, trackFormInteractionCompleted]);

  const formOutput = (
    <FormProvider {...methods}>
      <RecaptchaForm onSubmit={submitForm} recaptchaRef={recaptchaRef} onFocus={trackFormInteractionStart}>
        <Stack gap="6">
          <Stack
            direction={{ initial: 'column', md: 'row' }}
            gap="6"
            alignY="justify"
            alignX={{ initial: undefined, md: 'justify' }}>
            <Stack.Item grow>
              <InputText
                {...register('emailaddress1')}
                label={emailAddressValue?.label ?? `Email`}
                placeholder={emailAddressValue?.placeholder ?? `mijn@email.nl`}
                hint={emailAddressValue?.hint}
                error={getEmailErrorMessage()}
              />
            </Stack.Item>

            <Placeholder name="jss-form" />

            <Box paddingTop={{ md: '7' }}>
              <Stretch width={{ initial: true, md: false }} height={false}>
                <Button size="compact" type="submit">
                  {nextButtonText?.value}
                </Button>
              </Stretch>
            </Box>
          </Stack>
          <Text size="BodyS">
            <RichText html={disclaimerContent?.value} />
          </Text>
          <Text size="BodyS">
            <Recaptcha hasError={!!errors.recaptchaToken} ref={recaptchaRef} />
          </Text>
        </Stack>
      </RecaptchaForm>
    </FormProvider>
  );

  const showForm = !isSuccess && !isError;
  const showBackButton = isSuccess || isError;

  return (
    <TransitionOpacity in={!isTransitioning}>
      <Stack gap="6">
        {showForm && (
          <>
            <Placeholder name="jss-newsletter-form-intro" />
            {formOutput}
          </>
        )}

        {isSuccess && <Placeholder name="jss-newsletter-form-success" />}

        {isError && <Placeholder name="jss-newsletter-form-error" />}

        {showBackButton && (
          <TextLink
            type="button"
            onClick={() => {
              setTransitioning(true);
              setTimeout(() => {
                reset();
                setTransitioning(false);
              }, 500);
            }}
            emphasis="high">
            {backButtonText?.value}
          </TextLink>
        )}
      </Stack>
    </TransitionOpacity>
  );
};

export default RevolutionRegisterToNewsletterForm;

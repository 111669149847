export function getAllowedQueryParamValue<T extends string>(parameterName: string, allowedValues: T[]): T | null {
  if (typeof window === 'undefined') return null;

  const urlParams = new URLSearchParams(window.location.search);
  const queryParamValue = urlParams.get(parameterName);

  return queryParamValue &&
    !Array.isArray(queryParamValue) &&
    allowedValues.map(value => value.toString()).includes(queryParamValue)
    ? (queryParamValue as T)
    : null;
}

enum UTMSearchParams {
  utm_source = 'utm_source',
  utm_medium = 'utm_medium',
  utm_campaign = 'utm_campaign',
  utm_content = 'utm_content',
  utm_term = 'utm_term',
}

type UTMParams = Partial<Record<UTMSearchParams, string>>;

export function getUTMParams(): UTMParams | null {
  if (typeof window === 'undefined') return null;

  const urlParams = new URLSearchParams(window.location.search);

  return Object.values(UTMSearchParams).reduce<UTMParams>((utms, param) => {
    if (urlParams.has(param)) {
      utms[param] = urlParams.get(param) ?? '';
    }

    return utms;
  }, {});
}

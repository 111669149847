import { getUTMParams } from '@common/querystring';
import { RequestModels_Forms_V2_FormsRequestModelV2, RequestModels_Forms_B2BFormRequest } from '@monorepo-types/dc';

import { getFormFieldKey } from './revolutionFormHelper';
import { FormValues } from './types';

// Not all generic form field values are part of the Forms V2 request model in DC. Any values that are not supported
// are sent in 'formFields' instead, and are consciously omitted from DATA_KEYS.
const DATA_KEYS = [
  'emailAddress',
  'houseNumber',
  'houseNumberSuffix',
  'initials',
  'postalCode',
  'street',
  'surname',
  'surnamePreposition',
];

export function prepareRequest(senderIdOption: string, input: FormValues): RequestModels_Forms_V2_FormsRequestModelV2 {
  const data: Record<string, unknown> = {};
  const formFields: Array<{ key: string; value: string }> = [];

  for (const key in input) {
    if (key === 'recaptchaToken') continue;

    if (DATA_KEYS.includes(key)) {
      data[key] = input[key];
    } else {
      let value = input[key];

      if (Array.isArray(value)) {
        value = value.join(',');
      }

      formFields.push({ key, value: `${value}` });
    }
  }

  return {
    data: {
      houseNumber: 0, // necessary, as DC types wrongly mark houseNumber as non-optional
      ...data,
      formFields,
      destinationCode: senderIdOption,
      urlOrigin: window.location.href,
    },
  };
}

enum CRMUTMKeysMapping {
  utm_source = 'enc_utmsource',
  utm_medium = 'enc_utmmedium',
  utm_campaign = 'enc_utmcampaign',
  utm_content = 'enc_utmcontent',
  utm_term = 'enc_utmterm',
  utm_url = 'enc_utmwebpageurl',
}

export function prepareRevolutionFormRequest(senderId: string, input: FormValues): RequestModels_Forms_B2BFormRequest {
  // 'enc_utmwebpageurl' is a CRM specific field that is used to store the current page URL
  const fields: Array<{ key: string; value: string }> = [
    { key: CRMUTMKeysMapping.utm_url, value: window.location.href },
  ];

  for (const key in input) {
    if (key === 'recaptchaToken') continue;
    fields.push({ key: getFormFieldKey(key), value: `${input[key]}` });
  }

  const utmParams = getUTMParams() ?? {};
  const utmFields = Object.entries(utmParams).map(([key, value]) => ({
    key: CRMUTMKeysMapping[key as keyof typeof CRMUTMKeysMapping] || key,
    value: value || '',
  }));

  fields.push(...utmFields);

  return {
    data: {
      fields,
      formId: senderId,
      publishedFormUrl: window.location.href,
    },
  };
}
